<template>
  <div class="pages">
    <div class="record-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>关注楼盘</span>
        </div>
      </div>
    </div>
    <div class="record-list">
      <div class="list" v-for="(item,index) in list" :key="index">
        <div class="pic">
          <img :src="item.project.image" alt="">
        </div>
        <div class="title">
          <span>{{item.project.name}}</span>
          <div class="like" @click="onListDel(item,index)">
            <img src="/static/images/like_on.png" alt="">
          </div>
        </div>
        <div class="price-info">
          <div class="tit">
            <span>均价</span>
          </div>
          <div class="price">
            <span v-if="item.project.base_data.price_pending===1">待定</span>
            <span v-else>{{item.project.base_data.price_start}}-{{item.project.base_data.price_end}}</span>
          </div>
          <div class="tit" v-if="item.project.base_data.price_pending!==1">
            <span>元/㎡</span>
          </div>
        </div>
        <div class="desc">
          <span>[{{ item.project.city.name }}周边 {{ item.project.area.name }}] {{ item.project.base_data.address }}</span>
        </div>
        <div class="desc">
              <span>户型：{{ item.project.base_data.types_for_sale }}  | 建筑面积：{{
                  item?.project.base_data?.area_start
                }}㎡-{{ item?.project.base_data?.area_end }}㎡</span>
        </div>
        <div class="tag-list">
<!--          <div class="tag orange" v-if="item.base_data.primary_tag">-->
<!--            <span>{{ item.project.base_data.primary_tag }}</span>-->
<!--          </div>-->
          <div class="tag blue" v-if="item.project.base_data.product_status_id">
            <span v-if="item.project.base_data.product_status_id === 1">全城热销中</span>
            <span v-if="item.project.base_data.product_status_id === 2">火爆加推</span>
            <span v-if="item.project.base_data.product_status_id === 3">预售进行中</span>
            <span v-if="item.project.base_data.product_status_id === 4">待售</span>
          </div>
          <div class="tag gray"  v-for="(secondary_tag,secondary_tag_index) in item.project.base_data.secondary_tags"
               :key="secondary_tag_index">
                <span>{{ secondary_tag.tag }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="paging-info">
      <div class="pagination">
        <div class="num" :class="{active:item === page.current}" v-for="item in page.last" :key="item"
             @click="handlePageClick(item)">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="paging">
        <div class="cut" @click="handlePagePrevClick" v-show="page.current !== 1">
          <span class="left"></span>
        </div>
        <div class="cut" @click="handlePageNextClick" v-show="page.current !== page.last && list.length!==0">
          <span class="right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollectHouses",
  data(){
    return {
      loading: false,
      page: {
        current: 1,
        last: 1,
      },
      list: []
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    async getData() {
      this.loading = true
      try {
        let res = await this.http({
          url: '/api/my/collect',
          method: 'GET',
          params: {
            page: this.page.current,
          }
        })

        for(let index in res.data.list)
        {
          res.data.list[index].project.base_data.secondary_tags = JSON.parse(res.data.list[index].project.base_data.secondary_tags).slice(0,2)
        }

        this.page.current = res.data.page.current
        this.page.last = res.data.page.last
        this.list = res.data.list
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handlePageClick(page) {
      this.page.current = page
      this.getData()
    },
    handlePagePrevClick() {
      this.page.current--
      this.getData()
    },
    handlePageNextClick() {
      this.page.current++
      this.getData()
    },
    handleSearchClick() {
      this.page.current = 1
      this.getData()
    },
    /**
     * 取消收藏
     */
    async onListDel(item,index){
      try {
        let res = await this.http({
          url: '/api/report/projectUnCollect',
          method: 'POST',
          data: {
            project_id: item.project.id
          }
        })
        this.list.splice(index,1);
        this.$message({
          message: '取消收藏成功',
          type: 'success'
        })
        console.log(res);
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "CollectHouses.scss";
</style>
